import { Box, Stack, UseControllableStateProps } from '@chakra-ui/react';
import { VariantPicker } from './VariantPicker.tsx';
import { VariantSelector } from '../hooks/useVariantSelector.tsx';

interface ProductVariantPickerProps extends UseControllableStateProps<number> {
  variantSelector: VariantSelector;
  isShowingError: boolean;
}

export const ProductVariantPicker = (props: ProductVariantPickerProps) => {
  const { variantSelector } = props;

  return (
    <Box>
      <Stack spacing={{ base: '4', md: '8' }} direction={{ base: 'column', md: 'row' }} flex='1'>
        <VariantPicker
          label={'Pack Size'}
          value={variantSelector.selectedPackSize}
          onChange={variantSelector.setSelectedPackSize}
          options={variantSelector.packSizeOptions}
        />
      </Stack>
      {variantSelector.flavorOptions.length > 1 && (
        <Stack spacing={{ base: '4', md: '8' }} direction={{ base: 'column', md: 'row' }} flex='1'>
          <VariantPicker
            label={'Flavor'}
            value={variantSelector.selectedFlavor}
            onChange={variantSelector.setSelectedFlavor}
            options={variantSelector.flavorOptions}
          />
        </Stack>
      )}
      {props.isShowingError && <Box color={'red'}>Please select a variant</Box>}
    </Box>
  );
};
