import {
  Box,
  Button,
  Drawer,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Heading,
  HStack,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure
} from '@chakra-ui/react';
import { CartItem, CartItemProps } from './components/CartItem';
import { LoadingSpinner } from '@cksoftware/react-base';
import { useMemo } from 'react';
import { PriceTag } from '../components/PriceTag.tsx';
import { CheckoutModal } from '../checkout/checkout.tsx';
import { useShoppingCartData } from './hooks/useShoppingCartData.tsx';

type ShoppingCartProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const ShoppingCart = (props: ShoppingCartProps) => {
  const { isOpen, onClose } = props;

  const checkoutDisclosure = useDisclosure();
  const { shoppingCart, cartQuery } = useShoppingCartData();

  const sum = useMemo(() => {
    return cartQuery.data?.reduce((sum, current) => sum + current.quantity * current.price, 0) ?? 0;
  }, [cartQuery]);

  return (
    <Box height='100vh'>
      <Drawer isOpen={isOpen} onClose={onClose} size='md'>
        <DrawerOverlay />
        <DrawerContent bg={useColorModeValue('white', 'gray.800')} overflowY='auto'>
          <DrawerCloseButton size='lg' right={{ base: '4', md: '8' }} top='4' bg='inherit' />
          <Stack padding={{ base: '6', md: '10' }} height='full' spacing='8' overflowY='auto'>
            <Heading size='md'>{'Shopping Cart' + ` (${shoppingCart.totalItems} items)`}</Heading>
            {!cartQuery.isLoading && cartQuery.data ? (
              <Stack spacing={{ base: '8', md: '10' }}>
                {cartQuery.data.map((item) => {
                  const cartItem: CartItemProps = {
                    ...item,
                    onChangeQuantity: (quantity) => shoppingCart.setQuantity(item.id, quantity),
                    onClickDelete: () => shoppingCart.removeItem(item.id)
                  };
                  return <CartItem key={item.id} {...cartItem} />;
                })}
              </Stack>
            ) : (
              <LoadingSpinner />
            )}
          </Stack>
          <Stack borderTopWidth='1px' px={{ base: '6', md: '10' }} py='4' spacing='5'>
            <Stack>
              <HStack fontSize='xl' fontWeight='semibold'>
                <Text flex='1'>Subtotal:</Text>
                {!cartQuery.isLoading && <PriceTag minimumPrice={sum} />}
              </HStack>
            </Stack>
            <Button
              isDisabled={cartQuery.data?.length == 0}
              colorScheme='blue'
              size='lg'
              fontSize='md'
              onClick={() => {
                props.onClose();
                checkoutDisclosure.onOpen();
              }}>
              Checkout
            </Button>
          </Stack>
        </DrawerContent>
      </Drawer>
      {/*really hacky way to clear form on close because reset isnt working*/}
      {checkoutDisclosure.isOpen && <CheckoutModal disclosure={checkoutDisclosure} />}
    </Box>
  );
};
