import { Flex } from '@chakra-ui/react';
import { UseFormReturn } from 'react-hook-form';
import { ProductQuickShopModel } from './ProductQuickShop.tsx';
import { createFieldHelper } from '@cksoftware/react-base';
import { useMemo } from 'react';

interface OrderTextAreaProps {
  formMethods: UseFormReturn<ProductQuickShopModel>;
}

export const OrderTextArea = (props: OrderTextAreaProps) => {
  const formBuilder = createFieldHelper<Partial<ProductQuickShopModel>>(props.formMethods);
  const fields = useMemo(() => {
    return [formBuilder.textArea('orderNote')];
  }, [formBuilder]);
  return <Flex flex={1}>{formBuilder.renderFieldHelperInput('orderNote', fields)}</Flex>;
};
