import { ProductVariantData } from '../models/ProductTypeItemData.ts';
import { useEffect, useState } from 'react';

export function usePrice(variants: ProductVariantData[]) {
  const [price, setPrice] = useState({
    minimumPrice: Math.min(...variants.map((variant) => variant.Price)),
    maximumPrice: Math.max(...variants.map((variant) => variant.Price))
  });

  useEffect(() => {
    const prices = variants.map((variant) => variant.Price);
    setPrice({
      minimumPrice: Math.max(...prices),
      maximumPrice: Math.min(...prices)
    });
  }, [variants]);

  return price;
}
