import { ChakraFormDialog, useHookForm } from '@cksoftware/react-base';
import { Box, Heading, Stack, StackProps, Text, useColorModeValue, UseDisclosureReturn } from '@chakra-ui/react';
import { CustomProductRequestFormModel } from './types.ts';
import { CustomProductRequestForm } from './components/customProductRequestForm.tsx';
import { Gallery } from '../components/Gallery.tsx';
import { useProductRequest } from '../hooks/useProductRequests.tsx';
import { useWatch } from 'react-hook-form';
import { useCustomRequestStateStore } from '../hooks/useCustomRequestStore.tsx';
import { useEffect, useState } from 'react';
import { ProductImage } from '../models/ProductImage.ts';

type Props = {
  disclosure: UseDisclosureReturn;
  rootProps?: StackProps;
};

export const CustomProductRequestModal = (props: Props) => {
  const { disclosure, rootProps } = props;
  const [name, setName] = useState<string>();
  const [description, setDescription] = useState<string>();
  const [images, setImages] = useState<ProductImage[]>([]);

  const { formMethods } = useHookForm<CustomProductRequestFormModel>({
    drugId: undefined!,
    formId: undefined!,
    usageId: undefined!,
    strength: undefined!,
    packSize: undefined!,
    strengthUnitId: undefined!,
    strengthDenominatorUnitId: undefined!,
    flavorId: undefined!,
    orderNote: undefined!,
    quantity: 0
  });

  const requestStore = useCustomRequestStateStore();

  const onSubmit = async (data: CustomProductRequestFormModel) => {
    if (!data.drugId || !data.formId || !data.usageId || !data.strength || !data.strengthUnitId || !data.packSize) {
      return; //todo what to do here? we know they are not null due to validation?
    }

    requestStore.addItem({
      drugId: data.drugId,
      formId: data.formId,
      usageId: data.usageId,
      flavorId: data.flavorId,
      strength: data.strength,
      strengthUnitId: data.strengthUnitId,
      strengthDenominatorUnitId: data.strengthDenominatorUnitId,
      packSize: data.packSize,
      orderNote: data.orderNote,
      quantity: data.quantity
    });

    formMethods.reset();
    disclosure.onClose();
  };

  const requestFormWatch = useWatch({
    control: formMethods.control
  });

  const requestedProduct = useProductRequest(requestFormWatch); //todo don't send if all null

  useEffect(() => {
    if (requestedProduct.data && requestedProduct.data.Images !== images) {
      setImages(requestedProduct.data.Images ?? []);
    }
  }, [requestedProduct.data?.Images]);

  useEffect(() => {
    if (requestedProduct.data && requestedProduct.data.Name !== name) {
      setName(requestedProduct.data.Name);
    }
  }, [requestedProduct.data?.Name]);

  useEffect(() => {
    if (requestedProduct.data && requestedProduct.data.Description !== description) {
      setDescription(requestedProduct.data.Description);
    }
  }, [requestedProduct.data?.Description]);

  return (
    <ChakraFormDialog<CustomProductRequestFormModel>
      disclosure={disclosure}
      formMethods={formMethods}
      onSubmit={onSubmit}
      size={{ base: 'full', md: 'xl' }}
      hideControls={true}>
      <Stack direction={{ base: 'column', md: 'row' }} spacing={{ base: '8', lg: '16' }} {...rootProps}>
        <Box flex='1'>
          <Gallery images={images} />
        </Box>
        <Box flex='1'>
          <Stack spacing={{ base: '4', md: '8' }}>
            <Stack spacing={{ base: '2', md: '4' }}>
              <Heading size='lg' fontWeight='medium'>
                {'Product Request' + (name ? `: ${name}` : '')}
              </Heading>
              <Text
                color={useColorModeValue('gray.600', 'gray.400')}
                dangerouslySetInnerHTML={{
                  __html: description ?? ''
                }}></Text>
            </Stack>
            <CustomProductRequestForm formMethods={formMethods} />
          </Stack>
        </Box>
      </Stack>
    </ChakraFormDialog>
  );
};
