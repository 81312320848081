import { CreateFieldHelperReturn, useGenericCrudGetQuery } from '@cksoftware/react-base';
import { CustomProductRequestFormModel, UnitModel } from '../types.ts';
import { UseFormReturn, useWatch } from 'react-hook-form';
import { useMemo } from 'react';
import { ControllerRoutes } from '../../../../constants/controllerRoutes.ts';

export const useCustomProductRequestFormFields = (
  formMethods: UseFormReturn<CustomProductRequestFormModel>,
  fieldHelper: CreateFieldHelperReturn<CustomProductRequestFormModel>
) => {
  const strengthDenominatorUnitId = useWatch({
    control: formMethods.control,
    name: 'strengthDenominatorUnitId'
  });

  const strengthDenominatorUnit = useGenericCrudGetQuery<UnitModel>(
    strengthDenominatorUnitId ? +strengthDenominatorUnitId : undefined,
    ControllerRoutes.Unit.Base
  );

  return useMemo(() => {
    return [
      fieldHelper
        .selectInputQueryItems('drugId', { searchUrl: ControllerRoutes.Drug.Select })
        .withLabel('Drug')
        .isString()
        .withValidation({ required: {} }),
      fieldHelper
        .selectInputQueryItems('formId', { searchUrl: ControllerRoutes.ProductForm.Select })
        .withLabel('Form')
        .isString()
        .withValidation({ required: {} }),
      fieldHelper.numericInput('strength').withLabel('Strength').withValidation({ required: {} }),
      fieldHelper
        .selectInputQueryItems('strengthUnitId', { searchUrl: ControllerRoutes.Unit.Select })
        .withLabel('Unit')
        .isString()
        .withValidation({ required: {} }),
      fieldHelper
        .selectInputQueryItems('strengthDenominatorUnitId', { searchUrl: ControllerRoutes.Unit.Select })
        .withLabel('Denominator')
        .isString(),
      fieldHelper
        .numericInput('packSize')
        .withLabel('Package Size' + (strengthDenominatorUnit.data ? ` (${strengthDenominatorUnit.data?.Name})` : ''))
        .withValidation({ required: {} }),
      fieldHelper
        .selectInputQueryItems('flavorId', { searchUrl: ControllerRoutes.Flavor.Select })
        .withLabel('Flavor')
        .isString(),
      fieldHelper
        .selectInputQueryItems('usageId', { searchUrl: ControllerRoutes.Usage.Select })
        .withLabel('Health Canada Approved Usage')
        .isString()
        .withValidation({ required: {} }),
      fieldHelper.textArea('orderNote')
    ];
  }, [fieldHelper, strengthDenominatorUnit]);
};
