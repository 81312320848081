import { useCustomRequestStateStore } from './useCustomRequestStore.tsx';
import { useMemo } from 'react';
import { useProductCartStateStore } from './useProductCartStateStore.tsx';
import { ProductImage } from '../models/ProductImage.ts';
import { ProductData } from '../shoppingCart/models/ProductData.ts';
import { CartItem } from '../models/CartItem.ts';

export type ProductCartItemDetails = {
  id: number;
  name: string;
  form: string;
  usage: string;
  strength: number;
  price: number;
  packSize: number;
  packSizeUnit?: string;
  strengthUnit: string;
  image: ProductImage;
  strengthDenominatorUnit?: string;
  flavor?: string;
  orderNote: string | undefined;
  quantity: number;
  isRequest?: boolean;
};

export function CreateProductCartItemDetails(
  id: number | undefined,
  product: ProductData | undefined,
  item: CartItem | undefined,
  isRequest?: boolean
): ProductCartItemDetails {
  return {
    id: id ?? 0,
    name: product?.Name ?? 'Unknown',
    price: product?.Price ?? 0,
    form: product?.Form ?? 'Unknown',
    packSize: product?.PackSize ?? 0,
    packSizeUnit: product?.PackSizeUnit,
    strength: product?.Strength ?? 0,
    strengthUnit: product?.StrengthUnit ?? 'Unknown',
    flavor: product?.Flavor ?? undefined,
    image: product?.Images && product?.Images.length > 0 ? product?.Images[0] : { Url: '', AltText: '' },
    usage: product?.Usage ?? 'Unknown',
    quantity: item?.quantity ?? 0,
    orderNote: item?.orderNote ?? '',
    isRequest
  };
}

export function useShoppingCartStateStore() {
  const requestStore = useCustomRequestStateStore();
  const productStore = useProductCartStateStore();

  const totalItem = useMemo(() => {
    return requestStore.items.length + productStore.items.length;
  }, [requestStore.items, productStore.items]);

  return {
    totalItems: totalItem,
    addProduct: productStore.addItem,
    addRequest: requestStore.addItem,
    clear: () => {
      productStore.clear();
      requestStore.clear();
    },
    setQuantity: (id: number, quantity: number) => {
      const product = productStore.items.find((x) => x.id === id);
      if (product) {
        productStore.updateItem(product.id, { ...product.data, quantity });
      }

      const request = requestStore.items.find((x) => x.id === id);
      if (request) {
        requestStore.updateItem(request.id, { ...request.data, quantity });
      }
    },
    removeItem: (id: number) => {
      productStore.removeItem(id);
      requestStore.removeItem(id);
    }
  };
}

export function useCartCount() {
  const requestStore = useCustomRequestStateStore();
  const productStore = useProductCartStateStore();

  return useMemo(() => {
    return requestStore.items.length + productStore.items.length;
  }, [requestStore.items, productStore.items]);
}
