import { useCallback, useState } from 'react';
import { ProductFilterState } from './useProductTypes.tsx';
import { debounce } from 'lodash';

export function useFilterState() {
  const [filterState, setProductFilter] = useState<ProductFilterState>({});

  const debouncedSetProductKeywordFilter = useCallback(
    debounce((newValue: string) => {
      setProductFilter((prevFilter) => ({
        ...prevFilter,
        keyword: !!newValue ? newValue : undefined
      }));
    }, 300),
    []
  );

  const setProductFormId = useCallback((newValue: number[]) => {
    setProductFilter((prevFilter) => ({
      ...prevFilter,
      formIds: !!newValue.length ? newValue.map((item) => Number(item)) : undefined
    }));
  }, []);

  return {
    filterState,
    setKeyword: debouncedSetProductKeywordFilter,
    setFormIds: setProductFormId
  };
}
