import { ChakraFormDialog, LoadingSpinner, useHookForm, useStandardToast } from '@cksoftware/react-base';
import { Box, Flex, Heading, HStack, Stack, Text, UseDisclosureReturn } from '@chakra-ui/react';
import { CartItem, CartItemProps } from '../shoppingCart/components/CartItem.tsx';
import { useShoppingCartData } from '../shoppingCart/hooks/useShoppingCartData.tsx';
import { CheckoutFormModel } from './types.ts';
import { CheckoutForm } from './components/checkoutForm.tsx';
import { useMemo } from 'react';
import { PriceTag } from '../components/PriceTag.tsx';
import { useProductCartStateStore } from '../hooks/useProductCartStateStore.tsx';
import { usePlaceOrderMutation } from './api/usePlaceOrderMutation.ts';

type Props = {
  disclosure: UseDisclosureReturn;
};

export const CheckoutModal = (props: Props) => {
  const { formMethods } = useHookForm<CheckoutFormModel>({
    AuthorizedUserId: undefined!,
    DeliveryAddress: undefined,
    DeliveryComments: undefined,
    DeliveryType: undefined!,
    InstructionsForUse: '',
    OwnerName: undefined,
    OwnerPhoneNumber: undefined,
    PatientType: undefined!,
    PetName: undefined,
    Refills: 0
  });
  const { shoppingCart, cartQuery } = useShoppingCartData();
  const productData = useProductCartStateStore();
  const placeOrderMutation = usePlaceOrderMutation();
  const toast = useStandardToast();
  const onSubmit = async (data: CheckoutFormModel) => {
    await placeOrderMutation.mutateAsync({
      ...data,
      Products: productData.items.map((r) => r.data)
    });
    toast.successToast('Order Placed', 'Thank you, your order has been submitted successfully.', 10000, true);
    shoppingCart.clear();
    formMethods.reset();
    props.disclosure.onClose();
  };

  const sum = useMemo(() => {
    return cartQuery.data?.reduce((sum, current) => sum + current.quantity * current.price, 0) ?? 0;
  }, [cartQuery]);

  return (
    <ChakraFormDialog<CheckoutFormModel>
      disclosure={props.disclosure}
      formMethods={formMethods}
      onSubmit={onSubmit}
      size={{ base: 'full', md: 'xl' }}>
      <Box>
        <Flex direction={{ base: 'column', md: 'row' }} gap={{ base: '2', md: '24px' }}>
          <Box padding={{ base: '2', md: '6' }} flex={'2'}>
            <Heading size='md'>Purchase Information</Heading>
            <Box paddingTop={{ base: '2', md: '2' }}>
              <CheckoutForm formMethods={formMethods} />
            </Box>
          </Box>
          <Box padding={{ base: '6', md: '6' }} flex='1' bg={'gray.100'}>
            <Heading size='md'>{'Shopping Cart' + ` (${shoppingCart.totalItems} items)`}</Heading>
            <Stack padding={{ base: '6', md: '10' }} spacing='8'>
              {!cartQuery.isLoading && cartQuery.data ? (
                <Stack>
                  {cartQuery.data.map((item) => {
                    const cartItem: CartItemProps = {
                      ...item,
                      onChangeQuantity: (quantity) => shoppingCart.setQuantity(item.id, quantity),
                      onClickDelete: () => shoppingCart.removeItem(item.id)
                    };
                    return <CartItem key={item.id} {...cartItem} />;
                  })}
                </Stack>
              ) : (
                <LoadingSpinner />
              )}
            </Stack>
            <Stack>
              <HStack fontSize='xl' fontWeight='semibold'>
                <Text flex='1'>Subtotal:</Text>
                {!cartQuery.isLoading && <PriceTag minimumPrice={sum} />}
              </HStack>
            </Stack>
          </Box>
        </Flex>
      </Box>
    </ChakraFormDialog>
  );
};
