export const ControllerRoutes = {
  Util: {
    UsersInRole: '/UsersInRole',
    selectOption: {
      enumType: (type: string) => `/selectOption/enum/${type}`,
      authorizedVets: `/selectOption/authorizedVets`
    }
  },
  Drug: {
    Select: '/Drug/Select'
  },
  Unit: {
    Base: '/Unit',
    Select: '/Unit/Select'
  },
  Product: {
    Base: '/Product'
  },
  ProductRequest: {
    Base: '/ProductRequest'
  },
  ProductType: {
    Base: '/ProductType'
  },
  ProductForm: {
    Base: '/ProductForm',
    Select: '/ProductForm/Select'
  },
  Companies: {
    Base: '/Company'
  },
  Flavor: {
    Select: '/Flavor/Select'
  },
  Usage: {
    Select: '/Usage/Select'
  },
  order: {
    place: '/order'
  },
  ProductAdmin: {
    Unit: {
      Base: '/ProductAdmin/StrengthUnit',
      Select: '/ProductAdmin/StrengthUnit/Select'
    },
    Form: {
      Base: '/ProductAdmin/Form',
      Select: '/ProductAdmin/Form/Select'
    },
    Flavor: {
      Base: '/ProductAdmin/Flavor',
      Select: '/ProductAdmin/Flavor/Select'
    },
    Usage: {
      Base: '/ProductAdmin/Usage',
      Select: '/ProductAdmin/Usage/Select'
    },
    Drugs: '/ProductAdmin/Drug'
  }
};
