import { useEffect, useState } from 'react';
import { ProductVariantData } from '../models/ProductTypeItemData.ts';
import { Option } from '../components/VariantPicker.tsx';

export type VariantSelector = {
  setSelectedFlavor: (value: ((prevState: string | undefined) => string | undefined) | string | undefined) => void;
  selectedFlavor: string | undefined;
  flavorOptions: Option[];
  setSelectedPackSize: (value: ((prevState: string | undefined) => string | undefined) | string | undefined) => void;
  selectedVariants: ProductVariantData[];
  selectedPackSize: string | undefined;
  packSizeOptions: Option[];
};

export function useVariantSelector(
  variants: ProductVariantData[],
  onSelected: (productVariantId: number | undefined) => void
): VariantSelector {
  const [selectedPackSize, setSelectedPackSize] = useState<string>();
  const [selectedFlavor, setSelectedFlavor] = useState<string>();
  const [selectedVariants, setSelectedVariants] = useState<ProductVariantData[]>(variants);

  const packSizeOptions: Option[] = [...new Set(variants.map((item) => item.Quantity))].map((quantity) => ({
    label: quantity.toString(),
    value: quantity.toString()
  }));

  const flavorOptions: Option[] = [...new Set(variants.map((item) => item.Flavor))].map((flavor) => ({
    label: flavor,
    value: flavor
  }));

  useEffect(() => {
    const newSelectedVariants = variants.filter((variant) => {
      return (
        (!selectedPackSize || variant.Quantity == Number(selectedPackSize)) &&
        (!selectedFlavor || variant.Flavor === selectedFlavor)
      );
    });
    setSelectedVariants(newSelectedVariants);
    onSelected(newSelectedVariants.length == 1 ? newSelectedVariants[0].Id : undefined);
  }, [selectedPackSize, selectedFlavor]);

  return {
    selectedPackSize: selectedPackSize,
    setSelectedPackSize: setSelectedPackSize,
    packSizeOptions: packSizeOptions,
    selectedFlavor: selectedFlavor,
    setSelectedFlavor: setSelectedFlavor,
    flavorOptions: flavorOptions,
    selectedVariants: selectedVariants
  };
}
