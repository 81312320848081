import { keepPreviousData, useQueries } from '@tanstack/react-query';
import { getAxios } from '../../../../util/axios.ts';
import { ControllerRoutes } from '../../../../constants/controllerRoutes.ts';
import { ProductData } from '../models/ProductData.ts';
import { useProductCartStateStore } from '../../hooks/useProductCartStateStore.tsx';

const ProductKeys = {
  all: [{ scope: 'products' }] as const,
  details: () => [...ProductKeys.all, 'detail'] as const,
  detail: (id: number) => [{ ...ProductKeys.details(), id }] as const
};

export function useProducts() {
  const productStore = useProductCartStateStore();
  return useQueries({
    queries: productStore.items.map((product) => ({
      queryKey: ProductKeys.detail(product.data.productVariantId),
      queryFn: async () =>
        (await getAxios().get<ProductData>(`${ControllerRoutes.Product.Base}/${product.data.productVariantId}`)).data,
      select: (data: ProductData) => ({ id: product.id, product: data, cart: product.data }),
      keepPreviousData: keepPreviousData
    }))
  });
}
