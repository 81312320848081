import { keepPreviousData, QueryFunctionContext, useQueries, useQuery } from '@tanstack/react-query';
import { getAxios } from '../../../util/axios.ts';
import { ControllerRoutes } from '../../../constants/controllerRoutes.ts';
import { ProductRequestDetailData } from '../models/ProductRequestDetailData.ts';
import { useCustomRequestStateStore } from './useCustomRequestStore.tsx';

const ProductRequestKeys = {
  all: [{ scope: 'productRequests' }] as const,
  details: () => [...ProductRequestKeys.all, 'detail'] as const,
  detail: (request: ProductRequest) => [{ ...ProductRequestKeys.details(), request }] as const
};

async function fetchProductRequest({
  queryKey: [{ request }]
}: QueryFunctionContext<ReturnType<(typeof ProductRequestKeys)['detail']>>): Promise<ProductRequestDetailData> {
  return (
    await getAxios().get<ProductRequestDetailData>(ControllerRoutes.ProductRequest.Base, {
      params: { ...request },
      paramsSerializer: {
        indexes: null // use brackets with indexes
      }
    })
  ).data;
}

type ProductRequest = {
  drugId?: string;
  formId?: string;
  strength?: number;
  strengthUnitId?: string;
  strengthUnitDenominatorId?: string;
  packSize?: number;
  flavorId?: string;
};

export function useProductRequests() {
  const requestStore = useCustomRequestStateStore();
  return useQueries({
    queries: requestStore.items.map((productRequest) => ({
      queryKey: ProductRequestKeys.detail({
        drugId: productRequest.data.drugId,
        formId: productRequest.data.formId,
        strength: productRequest.data.strength,
        strengthUnitId: productRequest.data.strengthUnitId,
        strengthUnitDenominatorId: productRequest.data.strengthDenominatorUnitId,
        packSize: productRequest.data.packSize,
        flavorId: productRequest.data.flavorId
      }), //todo there has to be a better way :,(
      queryFn: fetchProductRequest,
      select: (data: ProductRequestDetailData) => ({ id: productRequest.id, product: data, cart: productRequest.data }),
      keepPreviousData: keepPreviousData
    }))
  });
}

export function useProductRequest(request: ProductRequest) {
  return useQuery({
    queryKey: ProductRequestKeys.detail({
      drugId: request.drugId,
      formId: request.formId,
      strength: request.strength,
      strengthUnitId: request.strengthUnitId,
      strengthUnitDenominatorId: request.strengthUnitDenominatorId,
      flavorId: request.flavorId
    }), //todo there has to be a better way :,(
    queryFn: fetchProductRequest
  });
}
