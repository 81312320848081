import { FormControl, FormControlProps, FormLabel, HStack, useRadioGroup, UseRadioGroupProps } from '@chakra-ui/react';
import { VariantPickerButton } from './VariantPickerButton.tsx';

export interface Option {
  label: string;
  value: string;
}

interface VariantPickerProps extends UseRadioGroupProps {
  options: Option[];
  rootProps?: FormControlProps;
  hideLabel?: boolean;
  label: string;
}

export const VariantPicker = (props: VariantPickerProps) => {
  const { options, rootProps, hideLabel, label, ...rest } = props;
  const { getRadioProps, getRootProps, value } = useRadioGroup(rest);
  const selectedOption = options.find((option) => option.value == value);

  return (
    <FormControl {...rootProps}>
      {!hideLabel && (
        <FormLabel variant={'standard'}>{`${label}: ${selectedOption?.label ?? 'Not Selected'}`}</FormLabel>
      )}
      <HStack {...getRootProps()}>
        {options.map((option) => (
          <VariantPickerButton key={option.value} label={option.label} {...getRadioProps({ value: option.value })} />
        ))}
      </HStack>
    </FormControl>
  );
};
