import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { ItemStore, itemStoreMethods } from '../utils/itemStoreMethods.tsx';
import { CartItem } from '../models/CartItem.ts';

export type CustomRequestItem = CartItem & {
  drugId: string;
  formId: string;
  usageId: string;
  strength: number;
  packSize: number;
  strengthUnitId: string;
  strengthDenominatorUnitId?: string;
  flavorId?: string;
};

export const useCustomRequestStateStore = create(
  persist<ItemStore<CustomRequestItem>>((set) => itemStoreMethods<CustomRequestItem>(set), {
    name: 'custom-request-state'
  })
);
