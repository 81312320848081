import { Flex, HStack, Icon, Stack, StackDivider, Text, useDisclosure } from '@chakra-ui/react';
import { MdFilterList } from 'react-icons/md';
import { FilterDrawer } from './FilterDrawer';
import { sortByOption, SortBySelect } from './SortBySelect';
import { ProductFormFilter } from './ProductFormFilter.tsx';
import { ProductFilterState } from '../hooks/useProductTypes.tsx';

type Props = {
  filterState: ProductFilterState;
  setFormIds: (value: number[]) => void;
  onSelectSortBy: (value: sortByOption | undefined) => void;
  onClearAll?: VoidFunction;
};

export const MobileFilter = (props: Props) => {
  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <>
      <Flex width='full' justify='space-between' display={{ base: 'flex', md: 'none' }}>
        <HStack as='button' fontSize='sm' type='button' px='3' py='1' onClick={onOpen} borderWidth='1px' rounded='md'>
          <Icon as={MdFilterList} />
          <Text>Filters</Text>
        </HStack>
        <SortBySelect onSelectSortBy={props.onSelectSortBy} width='120px' defaultValue='23' placeholder='Sort' />
      </Flex>
      <FilterDrawer
        isOpen={isOpen}
        onClose={onClose}
        onClearAll={() => {
          props.onClearAll?.();
          onClose();
        }}>
        <Stack spacing='6' divider={<StackDivider />}>
          <ProductFormFilter value={props.filterState.formIds} onChange={props.setFormIds} label='Form' />
        </Stack>
      </FilterDrawer>
    </>
  );
};
