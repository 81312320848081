import { Box, Heading, HStack, Stack, StackProps, Text, useColorModeValue } from '@chakra-ui/react';
import { PriceTag } from './PriceTag';
import { QuantityPicker } from './QuantityPicker';
import { ProductTypeItemData } from '../models/ProductTypeItemData.ts';
import { Gallery } from './Gallery.tsx';
import { usePrice } from '../hooks/usePrice.tsx';
import { OrderTextArea } from './OrderTextArea.tsx';
import { FlexForm, useHookForm } from '@cksoftware/react-base';
import { ProductVariantPicker } from './ProductVariantPicker.tsx';
import { useVariantSelector } from '../hooks/useVariantSelector.tsx';
import { useProductCartStateStore } from '../hooks/useProductCartStateStore.tsx';
import { useState } from 'react';

interface ProductQuickShopProps {
  product: ProductTypeItemData;
  rootProps?: StackProps;
  onClose: () => void;
}

export type ProductQuickShopModel = {
  productVariantId?: number;
  orderNote: string;
  quantity: number;
};

export const ProductQuickShop = (props: ProductQuickShopProps) => {
  const { product, rootProps } = props;
  const [showVariantError, setShowVariantError] = useState(false);

  const { formMethods } = useHookForm<ProductQuickShopModel>({ orderNote: '', quantity: 1 });

  const variantSelector = useVariantSelector(product.Variants, (productVariantId) => {
    setShowVariantError(false);
    formMethods.setValue('productVariantId', productVariantId);
  });
  const price = usePrice(variantSelector.selectedVariants);

  const productStore = useProductCartStateStore();

  return (
    <Stack direction={{ base: 'column', md: 'row' }} spacing={{ base: '8', lg: '16' }} {...rootProps}>
      <Box flex='1'>
        <Gallery images={product.Images} />
      </Box>
      <Box flex='1'>
        <Stack spacing={{ base: '4', md: '8' }}>
          <Stack spacing={{ base: '2', md: '4' }}>
            <Heading size='lg' fontWeight='medium'>
              {product.DrugName}
            </Heading>
            <Stack direction={{ base: 'column', md: 'row' }} spacing='1' align='baseline' justify='space-between'>
              <PriceTag
                {...price}
                salePrice={undefined} //todo fix this
                rootProps={{ fontSize: 'xl' }}
              />{' '}
              // todo make price cleaner
            </Stack>
            <Text
              color={useColorModeValue('gray.600', 'gray.400')}
              dangerouslySetInnerHTML={{ __html: product.DrugDescription }}></Text>
          </Stack>
          <FlexForm
            formMethods={formMethods}
            formFields={[]}
            onSubmit={async (model) => {
              if (!model.productVariantId) {
                setShowVariantError(true);
                return;
              }
              productStore.addItem({
                productId: props.product.Id,
                productVariantId: model.productVariantId,
                orderNote: model.orderNote,
                quantity: model.quantity,
                itemComment: model.orderNote
              });
              props.onClose();
            }}
            buttonConfig={{
              submitButtonText: 'Add to cart',
              submitButtonProps: { style: { width: '100%' } }
            }}>
            <Stack direction={{ base: 'column', md: 'row' }} spacing={{ base: '8', lg: '16' }} {...rootProps}>
              <Box flex='1'>
                <Stack spacing={{ base: '4', md: '8' }}>
                  <ProductVariantPicker isShowingError={showVariantError} variantSelector={variantSelector} />
                  <Stack spacing={{ base: '4', md: '8' }} direction={{ base: 'column', md: 'row' }} flex='1'>
                    <OrderTextArea formMethods={formMethods} />
                  </Stack>
                  <HStack spacing={{ base: '4', md: '8' }} align='flex-end' justify='space-evenly'>
                    <Box flex='1'>
                      <QuantityPicker
                        max={99}
                        min={1}
                        value={formMethods.getValues('quantity')}
                        onChange={(value) =>
                          formMethods.setValue('quantity', value, {
                            shouldValidate: true,
                            shouldTouch: true,
                            shouldDirty: true
                          })
                        }
                      />
                    </Box>
                  </HStack>
                </Stack>
              </Box>
            </Stack>
          </FlexForm>
        </Stack>
      </Box>
    </Stack>
  );
};
