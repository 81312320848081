import { AddressModel } from '../../../globalModels/addressModel.ts';
import { ProductItem } from '../hooks/useProductCartStateStore.tsx';

export type CheckoutFormModel = {
  AuthorizedUserId: string;
  InstructionsForUse: string;
  Refills: number;
  PatientType: PatientType;
  DeliveryType: DeliveryType;
  DeliveryComments: string | undefined;
  DeliveryAddress: AddressModel | undefined;
  PetName: string | undefined;
  OwnerName: string | undefined;
  OwnerPhoneNumber: string | undefined;
};

export type OrderRequestModel = CheckoutFormModel & {
  Products: ProductItem[];
};

export enum PatientType {
  None = 0,
  OfficeUse = 1,
  Pet = 2
}

export enum DeliveryType {
  None = 0,
  DeliverToClinic = 1,
  DeliverToPatient = 3,
  Pickup = 4
}
