import { Box, Flex, Image, Link, Stack, Text, useColorModeValue } from '@chakra-ui/react';
import { PriceTag } from '../../components/PriceTag.tsx';
import { QuantityPicker } from './QuantityPicker.tsx';
import { ProductImage } from '../../models/ProductImage.ts';

export type CartItemProps = {
  name: string;
  price: number;
  form: string;
  strength: number;
  strengthUnit: string;
  flavor?: string;
  image: ProductImage;
  packSize: number;
  packSizeUnit?: string;
  quantity: number;
  onChangeQuantity?: (quantity: number) => void;
  onClickDelete?: () => void;
  isRequest?: boolean;
};

export const CartItem = (props: CartItemProps) => {
  const {
    name,
    price,
    form,
    strength,
    strengthUnit,
    flavor,
    image,
    quantity,
    onChangeQuantity,
    onClickDelete,
    packSize,
    packSizeUnit,
    isRequest
  } = props;
  const color = useColorModeValue('gray.500', 'gray.300');

  const description = `${form} ${strength}${strengthUnit}` + (flavor ? ` - ${flavor}` : '');

  return (
    <Stack direction='row' spacing='5'>
      <Image
        rounded='md'
        minWidth='24'
        maxWidth='24'
        height={{ base: '20', md: '24' }}
        fit='cover'
        src={image.Url}
        alt={image.AltText}
        draggable='false'
        loading='lazy'
      />
      <Stack width='full' spacing='3'>
        <Stack direction={{ base: 'column', md: 'row' }} spacing='3' alignItems='flex-start'>
          <Stack spacing='0.5' width='full'>
            <Text fontWeight='medium'>{name}</Text>
            <Text fontWeight='medium'>
              Pack Size: {packSize}
              {packSizeUnit ?? ''}
            </Text>
            <Text color={color}>{description}</Text>
          </Stack>
          <PriceTag minimumPrice={price} isRequest={isRequest} />
        </Stack>
        <Flex width='full' justifyContent='space-between' alignItems='center'>
          <Box>
            <QuantityPicker
              max={99}
              min={1}
              value={quantity}
              onChange={onChangeQuantity}
              rootProps={{ height: '32px' }}
            />
          </Box>
          <Link as='button' type='button' fontWeight='medium' fontSize='sm' color={color} onClick={onClickDelete}>
            Remove
          </Link>
        </Flex>
      </Stack>
    </Stack>
  );
};
