import { UseFormReturn } from 'react-hook-form';
import { CustomProductRequestFormModel } from '../types.ts';
import { createFieldHelper, FormControls } from '@cksoftware/react-base';
import { Flex, Text } from '@chakra-ui/react';
import { useCustomProductRequestFormFields } from '../util/useCustomProductRequestFormFields.tsx';
import { QuantityPicker } from '../../components/QuantityPicker.tsx';

type Props = {
  formMethods: UseFormReturn<CustomProductRequestFormModel>;
};

export const CustomProductRequestForm = (props: Props) => {
  const fieldHelper = createFieldHelper<CustomProductRequestFormModel>(props.formMethods, undefined);
  const fields = useCustomProductRequestFormFields(props.formMethods, fieldHelper);
  const formGap = '8px';

  return (
    <Flex flexDir={'column'} gap={{ base: '8', lg: '16' }}>
      <Flex flexDir={'column'} gap={formGap}>
        <Flex gap={formGap} direction={{ base: 'column', md: 'row' }}>
          {fieldHelper.renderFieldHelperInput('drugId', fields)}
          {fieldHelper.renderFieldHelperInput('formId', fields)}
        </Flex>
        <Flex gap={formGap} direction={{ md: 'row' }}>
          {fieldHelper.renderFieldHelperInput('strength', fields)}
          {fieldHelper.renderFieldHelperInput('strengthUnitId', fields)}
          <Text>/</Text>
          {fieldHelper.renderFieldHelperInput('strengthDenominatorUnitId', fields)}
        </Flex>
        <Flex gap={formGap} direction={{ base: 'column', md: 'row' }}>
          {fieldHelper.renderFieldHelperInput('packSize', fields)}
        </Flex>
        <Flex>{fieldHelper.renderFieldHelperInput('flavorId', fields)}</Flex>
        <Flex>{fieldHelper.renderFieldHelperInput('usageId', fields)}</Flex>
      </Flex>
      <Flex flexDir={'column'} gap={formGap}>
        <Flex
          gap={formGap}
          direction={{
            base: 'column',
            md: 'row'
          }}>
          {fieldHelper.renderFieldHelperInput('orderNote', fields)}
        </Flex>
        <QuantityPicker
          max={99}
          min={1}
          value={props.formMethods.getValues('quantity')}
          onChange={(value) =>
            props.formMethods.setValue('quantity', value, {
              shouldValidate: true,
              shouldTouch: true,
              shouldDirty: true
            })
          }
        />
        <FormControls submitButtonText={'Add To Cart'} submitButtonProps={{ style: { width: '100%' } }} />
      </Flex>
    </Flex>
  );
};
