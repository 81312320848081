import { Box, Flex, HStack, Link, StackDivider, useColorModeValue as mode, useBreakpointValue } from '@chakra-ui/react';
import { PriceTag } from './PriceTag';
import { ProductMeta } from './ProductMeta';
import { ProductTypeItemData } from '../models/ProductTypeItemData.ts';

type ProductItemProps = ProductTypeItemData & {
  onClickOrder?: () => void;
};

export const ProductItem = (props: ProductItemProps) => {
  const { Images, IsOutOfStock, DrugName, Variants, onClickOrder, Form } = props;

  const isMobile = useBreakpointValue({ base: true, md: false });

  return isMobile ? (
    <Box>
      <Flex>
        <ProductMeta isOutOfStock={IsOutOfStock} name={DrugName} variants={Variants} image={Images[0]} form={Form} />
        {Variants.length > 0 && (
          <PriceTag
            minimumPrice={Variants.reduce(
              (acc: number, curr: { Price: number }) => (acc < curr.Price ? acc : curr.Price),
              Variants[0].Price
            )}
            maximumPrice={Variants.reduce(
              (acc: number, curr: { Price: number }) => (acc > curr.Price ? acc : curr.Price),
              Variants[0].Price
            )}
            rootProps={{ pt: '1', fontSize: 'sm', fontWeight: 'semibold' }}
          />
        )}
      </Flex>
      <HStack
        mt='2'
        fontSize='sm'
        fontWeight='medium'
        divider={<StackDivider />}
        spacing='3'
        color={mode('blue.600', 'blue.300')}>
        <Link as='button' type='button' onClick={onClickOrder}>
          Order Now
        </Link>
      </HStack>
    </Box>
  ) : (
    <Flex align='flex-start' justify='space-between'>
      <ProductMeta isOutOfStock={IsOutOfStock} name={DrugName} variants={Variants} image={Images[0]} form={Form} />
      <Flex direction='column' align='flex-end' justify='space-between' width='full' maxW='2xs' minH='16'>
        {Variants.length > 0 && (
          <PriceTag
            minimumPrice={Variants.reduce(
              (acc: number, curr: { Price: number }) => (acc < curr.Price ? acc : curr.Price),
              Variants[0].Price
            )}
            maximumPrice={Variants.reduce(
              (acc: number, curr: { Price: number }) => (acc > curr.Price ? acc : curr.Price),
              Variants[0].Price
            )}
            rootProps={{ fontWeight: 'semibold' }}
          />
        )}

        <Flex direction='column' align='center'>
          <HStack
            mt='2'
            fontSize='sm'
            fontWeight='medium'
            divider={<StackDivider />}
            spacing='3'
            color={mode('blue.600', 'blue.300')}>
            <Link as='button' type='button' fontWeight='medium' onClick={onClickOrder}>
              Order Now
            </Link>
          </HStack>
        </Flex>
      </Flex>
    </Flex>
  );
};
