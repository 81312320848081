import { UseQueryResult } from '@tanstack/react-query';
import { useMemo } from 'react';

export function useQueryLoadingState(queries: UseQueryResult[], otherFunc?: () => boolean) {
  const isLoading = useMemo(() => {
    let toReturn = false;
    queries.forEach((val) => {
      if (val.isLoading || !val.data) {
        toReturn = true;
      }
    });
    if (otherFunc) {
      const result = otherFunc();
      if (result) {
        toReturn = true;
      }
    }
    return toReturn;
  }, [queries, otherFunc]);
  return { isLoading };
}
