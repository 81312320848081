import { CreateProductCartItemDetails, useShoppingCartStateStore } from '../../hooks/useShoppingCartStore.tsx';
import { useProductRequests } from '../../hooks/useProductRequests.tsx';
import { useQueryLoadingState } from '../../../../util/useQueryLoadingState.tsx';
import { useProducts } from './useProducts.tsx';
import { useMemo } from 'react';

export const useShoppingCartData = () => {
  const shoppingCart = useShoppingCartStateStore();

  const productRequestCartQuery = useProductRequests();
  const productRequestCartQueryLoadingState = useQueryLoadingState(productRequestCartQuery);

  const productCartQuery = useProducts();
  const productCartQueryLoadingState = useQueryLoadingState(productCartQuery);

  const cartQuery = useMemo(() => {
    const productRequestCartQueryData = productRequestCartQuery.map((result) =>
      CreateProductCartItemDetails(result.data?.id, result.data?.product, result.data?.cart, true)
    );

    const productCartQueryData = productCartQuery.map((result) =>
      CreateProductCartItemDetails(result.data?.id, result.data?.product, result.data?.cart)
    );

    return {
      isLoading: productRequestCartQueryLoadingState && productCartQueryLoadingState.isLoading,
      data: productRequestCartQueryData.concat(productCartQueryData)
    };
  }, [productRequestCartQuery, productRequestCartQueryLoadingState, productCartQuery, productCartQueryLoadingState]);

  return { shoppingCart, cartQuery };
};
