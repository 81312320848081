import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { ItemStore, itemStoreMethods } from '../utils/itemStoreMethods.tsx';
import { CartItem } from '../models/CartItem.ts';

export type ProductItem = CartItem & {
  productId: number;
  productVariantId: number;
  itemComment: string;
};

export const useProductCartStateStore = create(
  persist<ItemStore<ProductItem>>((set) => itemStoreMethods<ProductItem>(set), {
    name: 'shopping-cart-state'
  })
);
