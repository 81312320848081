import { Box, Grid, Heading, HStack, Stack, Text, useDisclosure } from '@chakra-ui/react';
import { useCallback, useState } from 'react';
import { PagingState, ProductFilterState, SortingState, useProductTypes } from '../hooks/useProductTypes.tsx';
import { sortByOption, SortBySelect } from './SortBySelect.tsx';
import { ProductFormFilter } from './ProductFormFilter.tsx';
import { MobileFilter } from './MobileFilter.tsx';
import { ProductList } from './ProductList.tsx';
import { ChakraTablePager } from './ChakraTablePager.tsx';
import { ProductView } from './ProductView.tsx';

type Props = {
  filterState: ProductFilterState;
  setFormIds: (value: number[]) => void;
};

export const ProductCatalogue = (props: Props) => {
  const [sortingState, setSortingState] = useState<SortingState>({});
  const [pagingState, setPagingState] = useState<PagingState>({
    page: 0,
    pageSize: 50
  });
  const pageSizes = [50, 100, 150];

  const { data } = useProductTypes({
    pagingState: pagingState,
    sortingState: sortingState,
    filterState: props.filterState
  });

  const sortingStateMapper: (sortBy: sortByOption | undefined, previousSortingState: SortingState) => SortingState =
    useCallback((sortBy: sortByOption | undefined, previousSortingState: SortingState) => {
      if (sortBy === 'low-to-high') return { ...previousSortingState, orderBy: 'packSizePrice', isDesc: false };
      else if (sortBy === 'high-to-low') return { ...previousSortingState, orderBy: 'packSizePrice', isDesc: true };
      return { ...previousSortingState, orderBy: undefined, isDesc: undefined };
    }, []); //todo get rid of this

  const { isOpen, onOpen, onClose } = useDisclosure(); // todo should this be here?
  const [selectedProductId, setSelectedProductId] = useState<number | null>(null);
  const handleProductViewOpen = useCallback(
    (productId: number) => {
      setSelectedProductId(productId);
      onOpen();
    },
    [onOpen]
  ); // todo should this be here?

  return (
    <Box maxW='7xl' mx='auto' px={{ base: '4', md: '8', lg: '12' }} py={{ base: '6', md: '8', lg: '12' }}>
      <Grid templateColumns={{ base: '1fr', md: '240px 1fr' }} gap='14'>
        <Stack spacing='10' maxW='240px' display={{ base: 'none', md: 'flex' }}>
          <ProductFormFilter spacing='3' label='Form' onChange={props.setFormIds} />
        </Stack>

        <Box width='full'>
          <Stack
            spacing={{ base: '6', md: '4' }}
            direction={{ base: 'column', md: 'row' }}
            justify='space-between'
            align='flex-start'
            width='full'>
            <Stack direction={{ base: 'column', md: 'row' }} align='baseline'>
              <Heading size='md' fontSize='2xl'>
                Products
              </Heading>
              <Text color='gray.500'>{`(${data?.TotalRows ?? 0} products)`}</Text>
            </Stack>
            <MobileFilter
              filterState={props.filterState}
              setFormIds={props.setFormIds}
              onSelectSortBy={(newValue) => {
                setSortingState((prevFilter) => sortingStateMapper(newValue, prevFilter));
              }}
              onClearAll={() => props.setFormIds([])}
            />
            <HStack display={{ base: 'none', md: 'flex' }}>
              <Text flexShrink={0} color='gray.500' fontSize='sm'>
                Sort by
              </Text>
              <SortBySelect
                placeholder='Sort'
                onSelectSortBy={(newValue) => {
                  setSortingState((prevFilter) => sortingStateMapper(newValue, prevFilter));
                }}
              />
            </HStack>
          </Stack>
          <Box mt='6' borderWidth='2px' minH='480px' rounded='xl' borderStyle='dashed'>
            <ProductList
              filterState={props.filterState}
              sortingState={sortingState}
              pagingState={pagingState}
              onProductViewOpen={handleProductViewOpen}
            />
            <ChakraTablePager
              pageSize={pagingState.pageSize}
              pageIndex={pagingState.page}
              pageSizeOptions={pageSizes}
              setPageIndex={(index) => {
                const current = { ...pagingState };
                current.page = index;
                setPagingState(current);
              }}
              setPageSize={(pageSize) => {
                const current = { ...pagingState };
                current.pageSize = pageSize;
                setPagingState(current);
              }}
              totalItemsCount={data?.TotalRows ?? 0}
              showOptions={true}
              showQuantity={true}
            />
          </Box>
        </Box>
      </Grid>
      {selectedProductId && <ProductView productId={selectedProductId} isOpen={isOpen} onClose={onClose} />}
    </Box>
  );
};
